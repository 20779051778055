import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IArquitectData, getFullResults } from '../../hooks/useResultsFeed';
import * as XLSX from 'xlsx';

type MyProps = {
    signature: string
};

function convertISODatetoDMY(date: string) {
    const d = new Date(date);
    return  d.getDate()  + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
}

export default function FullResultsDownloadButton({
    signature
}: MyProps) {
    const [downloading, setDownloading] = React.useState(false);
    const [error,setError] = useState<string|null>(null);
    const {t} = useTranslation();
    const onDownload = async () => {
        setDownloading(true);
        const results = await getFullResults(signature);
        if( results.error ) {
            setError(results.error);
        } else {
            let lines = [1];
            const mapped = results.rows.map((row: IArquitectData) => {
                const ret= {
                    'Núm. colegiado': row.num_colegiado,
                    'Nombre': row.nombre,
                    'Apellidos': row.apellidos,
                    'E-mail': row.email,
                    'Teléfono': row.phone,
                    'Delegación': row.delegacion,
                    'Ciudad': row.ciudad,
                    'Código postal': row.zip_code,
                    'Formaciones': row.formaciones,
                    'Empresas': row.empresas,
                    'Links': row.web
                    
                };
                lines.push( Math.max(Math.max( ret.Formaciones.split(/\n/).length, ret.Empresas.split(/\n/).length ), 1 ));
                return ret;
            });

            const worksheet = XLSX.utils.json_to_sheet(mapped);
            // Adjust column width
            const wscols = [
                {wch: 13},
                {wch: 18},
                {wch: 25},
                {wch: 30},
                {wch: 10},
                {wch: 12},
                {wch: 12},
                {wch: 10},
                {wch: 80},
                {wch: 80},
                {wch: 60},
            ];
            worksheet['!cols'] = wscols;
            // I want all rows to have automatic height
            // Set row heights
            const wsrows = [];
  //          const merges = [];
            for (let i = 0; i < mapped.length + 1; i++) {
                wsrows.push({ hpt: lines[i] * 16,/*, hpx: null*/ });
/*                // Evitar overflow en las últimas columnas
                merges.push( { s: { r: i, c:8}, e: { r: i, c: 8 } } );
                merges.push( { s: { r: i, c:9}, e: { r: i, c: 9 } } );*/
            }
            worksheet['!rows'] = wsrows;
//            worksheet['!merges'] = merges;


            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Resultados");
            XLSX.writeFile(workbook, "resultados.xlsx");
        }
        setDownloading(false);
    };
    return <>
        {error && <div className='alert alert-danger'>{error}</div>}
        <button style={{float: 'right'}} 
            disabled={downloading}
            className='mt-1 btn btn-outline-secondary' onClick={onDownload}>{t('Descargar resultados')}</button>
        </>;

}